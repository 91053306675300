<br />
<div class="row justify-content-center align-items-center">
  <div class="col-lg-12">
    <p-toast></p-toast>
    <p-table #dt dataKey="id" [columns]="information.columns" [value]="information.content" [paginator]="true"
      [rows]="10" [rowsPerPageOptions]="[10, 25, 50]" stateStorage="local" [stateKey]="tableStateKey" [tableStyle]="{'min-width': '30rem',
            'font-family': 'apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji',
            'font-size':'14px'}">
      <ng-template pTemplate="caption">
        <div class="row justify-content-end align-items-end text-left">
          <div class="col-lg-12 justify-content-center">
            <button type="button" pButton pRipple icon="pi pi-file-excel" (click)="descargarExcel()"
              class="p-button-success mr-2" [style]=" {'background-color': '#22c55e'}" pTooltip="EXCEL"
              tooltipPosition="bottom"></button>
            <button type="button" pButton pRipple icon="pi pi-file-pdf" (click)="descargarPDF()"
              class="p-button-danger mr-2" pTooltip="PDF" tooltipPosition="bottom"></button>
            <p-multiSelect class="mSelect" [options]="columnsExport" defaultLabel="Columnas a descargar"
              optionLabel="title" optionValue="key" name="export" (onChange)="changeExportColumns($event)"
              [maxSelectedLabels]="3" [selectedItemsLabel]="'{0} columnas seleccionadas'">
            </p-multiSelect>
          </div>
        </div>
      </ng-template>
      <ng-template pTemplate="header" let columns>
        <tr class="header-class">
          <ng-container *ngFor="let column of information.columns">
            <th *ngIf="column.watch" [class]="column.class" [pSortableColumn]="column.key">
              {{ column.title }} <p-sortIcon [field]="column.key"></p-sortIcon>
            </th>
          </ng-container>
          <th class="text-center" style="width: 20%;">
            Acciones
          </th>
        </tr>
        <tr>
          <ng-container *ngFor="let column of information.columns">
            <th *ngIf="column.watch">
              <p-columnFilter type="text" [field]="column.key" [matchModeOptions]="matchModeOptions" [matchMode]="'contains'"></p-columnFilter>
            </th>
          </ng-container>
          <th></th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-row let-rowIndex="rowIndex" let-columns="columns">
        <tr class="body-class">
          <ng-container *ngFor="let column of columns">
            <td *ngIf="column.watch" [class]="column.class"
              [ngClass]="{ 'text-red': column.key === 'calificacionFinal' && row['calificacionFinal'] < calificacionMin, 'text-green': column.key === 'calificacionFinal' && row['calificacionFinal'] >= calificacionMin }">
              <span class="p-column-title font-weight-bold"> {{column.title}} </span>
              <div *ngIf="column.key === 'estatus_' else usePTag">
                <nz-tag [ngClass]="row[column.key]?.class">
                  {{ row[column.key]?.label ?? "N/A" }}
                </nz-tag>
              </div>

              <ng-template #usePTag>
                <div *ngIf="row.tag != null && column.tag != null && column.tag; else campoNormal">
                  <p-tag [style]="row.tag.style" [severity]="row.tag.severity" [rounded]="row.tag.rounded"
                    [icon]="row.tag.icon" [value]="row[column.key]"></p-tag>
                </div>
              </ng-template>

              <ng-template #campoNormal>
                <div>
                  <ng-container *ngIf="row[column.key] && includesSubstring(row[column.key], 'nz-icon'); else textContent">
                    <ng-container *ngIf="getTextTooltipContent(row[column.key]) as tooltipText">
                      <app-dynamic-icon
                        [iconType]="getIconType(row[column.key])"
                        [iconTheme]="getIconTheme(row[column.key])"
                        [iconStyles]="getIconStyle(row[column.key])"
                        [nz-tooltip]="tooltipText ? true : null"
                        [nzTooltipTitle]="tooltipText">
                      </app-dynamic-icon>
                    </ng-container>
                    <ng-container *ngIf="!getTextTooltipContent(row[column.key])">
                      <app-dynamic-icon
                        [iconType]="getIconType(row[column.key])"
                        [iconTheme]="getIconTheme(row[column.key])"
                        [iconStyles]="getIconStyle(row[column.key])"
                        [nz-tooltip]="tooltipText ? true : null"
                        [nzTooltipTitle]="tooltipText">
                      </app-dynamic-icon>
                    </ng-container>
                  </ng-container>
                  <ng-template #textContent>
                    {{ row[column.key] }}
                  </ng-template>
                </div>
              </ng-template>














            </td>
          </ng-container>


          <td *ngIf="information.typeMenu !== 0">
            <app-table-submenu *ngIf="row.tag.severity == 'success'" [typeMenu]="information.typeMenu"
              [service]="information.service" [row]="row" (actionResponseMenu)="responderPadre($event)"
              [controller]="information.controller" (editId)="emitirId($event)" (emitirIdRow)="emitirIdData($event)"
              (emitirIndexButton)="emitirIndexButtonData($event)" [acciones]="information.acciones"></app-table-submenu>
          </td>
        </tr>
      </ng-template>

      <ng-template pTemplate="emptymessage">
        <tr>
          <td [colSpan]="rowSpan" style="text-align: center;">
            <i class="pi pi-exclamation-circle" style="font-size: 2rem; color: #cecbcbd3;"></i>
            <br />Sin registros
          </td>
        </tr>
      </ng-template>

    </p-table>
    <p-confirmDialog [style]="{ width: '450px' }"></p-confirmDialog>
  </div>

</div>
