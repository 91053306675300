import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FilterMatchMode, FilterService, PrimeNGConfig, SelectItem, SortEvent } from 'primeng/api';
import { EnumMenu, IColumnsExport, IContentExport, IInformation, IInformationSimple, IResponseMenu } from 'src/app/models/shared/table';
import { ClasesService } from 'src/app/services/operacion/gruposHorarios/clases.service';
import { SpinService } from 'src/app/services/shared/spin.service';
import { TableService } from 'src/app/services/shared/table.service';

@Component({
  selector: 'app-simple-table-dynamic',
  templateUrl: './simple-table-dynamic.component.html',
  styleUrls: ['./simple-table-dynamic.component.css']
})
export class SimpleTableDynamicComponent implements OnInit {

  @Output() actionResponseMenu = new EventEmitter<IResponseMenu>();
  @Output() exportContent = new EventEmitter<IContentExport>();
  @Output() editId = new EventEmitter<number>();
  @Output() emitirIdRow = new EventEmitter<number>();
  @Output() emitirIndexButton = new EventEmitter<{ id, index }>();
  @Input() information: IInformationSimple =
    {
      title: '',
      typeMenu: EnumMenu.NONE,
      columns: [],
      content: [],
      service: {},
      acciones: [],
    };
  @Input() tableStateKey?: String;
  @Input() calificacionMin: number;

  rowSpan: number;
  selectedRows: any[];
  @Input() columnsSearch: string[] = [];
  columnsFilter: string[] = [];

  filterTable: string;
  //Datos a exportar
  columnsExport: IColumnsExport[] = [];
  selectedColumnsExport: IColumnsExport[] = [];
  columnsToExport: any[] = [];

  matchModeOptions: SelectItem[];
  constructor( private tableService: TableService, private spinService: SpinService, private filterService: FilterService,private primengConfig: PrimeNGConfig) { }


  ngOnInit(): void {
    this.rowSpan = this.information.columns.length + 1;
    this.columnsExport = this.tableService.columnasExportar(this.information);
    this.columnsSearch = this.information.columns.map(col => col.key);
    const customFilterName = 'custom-equals';
    this.primengConfig.setTranslation({
      noFilter: 'Sin filtro'
    });
    this.filterService.register(customFilterName, (value, filter): boolean => {
      if (filter === undefined || filter === null || filter.trim() === '') {
        return true;
      }

      if (value === undefined || value === null) {
        return false;
      }

      return value.toString() === filter.toString();
    });
    this.matchModeOptions = [
      { label: 'Comienza con', value: FilterMatchMode.STARTS_WITH },
      { label: 'Contiene', value: FilterMatchMode.CONTAINS },{ label: 'No contiene', value: FilterMatchMode.NOT_CONTAINS },
      { label: 'Termina con', value: FilterMatchMode.ENDS_WITH},{ label: 'Igual', value: customFilterName },
    ];
  }

  //Metodo para detectar las nuevas columnas ha exportar
  changeExportColumns(event: { value: string[] }) {
    this.selectedColumnsExport = event.value.map(value => {
      const itemsSelecionados = this.columnsExport.find(item => item.key === value);
      return {
        key: value,
        title: itemsSelecionados ? itemsSelecionados.title : '',
        isExport: true
      }
    });
  }

  includesSubstring(value: any, substring: string): boolean {
    return typeof value === 'string' && value.includes(substring);
  }

  getIconType(html: string): string {
    const match = html.match(/nzType="([^"]+)"/);
    return match ? match[1] : '';
  }

  getIconTheme(html: string): string {
    const match = html.match(/nzTheme="([^"]+)"/);
    return match ? match[1] : 'outline';
  }

  getTextContent(html: string): string {
    const match = html.match(/^(.*?)<span nz-icon/);
    return match ? match[1].trim() : html;
  }

  getIconStyle(html: string): { [key: string]: string } {
    const match = html.match(/style="([^"]+)"/i);
    if (match) {
      const styles = match[1].split(';');
      const styleObject: { [key: string]: string } = {};

      styles.forEach(style => {
        const trimmedStyle = style.trim();
        if (trimmedStyle) {
          const [property, value] = trimmedStyle.split(':');
          if (property && value) {
            styleObject[property.trim()] = value.trim();
          }
        }
      });

      return styleObject;
    }
    return {};
  }

  getTextTooltipContent(html: string): string | null {
    const match = html.match(/nz-tooltip\s+.*?nzTooltipTitle="([^"]+)"/);
    return match ? match[1].trim() : null;
  }




  emitirId(id: number) {
    this.editId.emit(id);
  }

  emitirIdData(id: number) {
    this.emitirIdRow.emit(id);
  }

  emitirIndexButtonData({id, index}){
    this.emitirIndexButton.emit({id, index});
  }

  responderPadre(event: IResponseMenu) {
    this.actionResponseMenu.emit({
      type: event.type,
      realizar: event.realizar,
    });
  }


  preparar() {
    this.columnsToExport = [];
    if (this.selectedColumnsExport != null && this.selectedColumnsExport.length > 0) {
      this.columnsToExport = this.selectedColumnsExport.map(i => ({ title: i.title, dataKey: i.key }));
    } else {
      this.columnsToExport = this.columnsExport.filter(i => i.isExport).map(i => ({ title: i.title, dataKey: i.key }));
    }
  }

  descargarPDF() {
    debugger
    this.preparar();
    this.tableService.exportPdf(this.columnsToExport ,this.information.content, this.information.title);
  }

  descargarExcel() {
    this.preparar();
    this.tableService.exportExcelHeaders(this.columnsToExport ,this.information.content,this.information.title)
  }

}
