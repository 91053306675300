
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzUploadChangeParam, NzUploadFile } from 'ng-zorro-antd/upload';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { PdfViewerComponent } from './../pdf-viewer/pdf-viewer.component';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush

})
export class UploadComponent implements OnInit {


  @Input() fileName?: string;
  @Input() fileUrl?: string;
  @Input() disabled?: boolean;
  @Output() fileSelected = new EventEmitter<NzUploadFile>();
  fileList: NzUploadFile[] = [];
  allowedExtensions = ['pdf', 'docx', 'xlsx', 'xlsl', 'gif', 'jpg', 'jpeg', 'png'];
  acceptExtensions = this.allowedExtensions.map(ext => `.${ext}`).join(',');

  env = environment.apiUrl;
  constructor(private modal: NzModalService, private sanitizer: DomSanitizer, private notification: NzNotificationService) { }

  ngOnInit(): void {
    if (this.fileName && this.fileUrl) {
      this.fileList = [
        {
          uid: '-1',
          name: this.fileName,
          status: 'done',
          url: this.env + this.fileUrl,
        }
      ];
    }
  }

  onFileChange(info: NzUploadChangeParam): void {
    if (info.file.status === 'done' || info.file.status === 'uploading') {
      this.fileSelected.emit(info.file);
    } else if (info.file.status === 'error') {
      console.error('Upload error:', info.file.error);
    }
  }
  handleChange(info: { file: NzUploadFile, fileList: NzUploadFile[] }): void {
    const { file, fileList } = info;

    this.fileList = fileList.slice(-1);
    if (file.status === 'done' || file.status === 'uploading') {
      this.renameAndEmitFile(file);
    } else if (file.status === 'error') {
      console.error('Upload error:', file.error);
    }
  }
  beforeUpload = (file: NzUploadFile): boolean => {
    this.renameAndEmitFile(file);
    return false; // Prevent automatic upload
  }
  renameAndEmitFile(file: NzUploadFile): void {
    let originFile = file.originFileObj as File;

    // If originFileObj is not available, use the file object directly
    if (!originFile) {
      originFile = file as any as File;
    }

    if (!originFile) {
      console.error('No originFileObj or valid file found:', file);
      return;
    }
    const originalExtension = originFile.name.split('.').pop();
    const baseName = originFile.name.substring(0, originFile.name.lastIndexOf('.')); // Nombre del archivo sin extensión

    // Asegúrate de que `this.fileName` no tenga una extensión
    const nameWithoutExtension = this.fileName && this.fileName.includes('.')
      ? this.fileName.substring(0, this.fileName.lastIndexOf('.'))
      : this.fileName || `new_name_${baseName}`;

    // Construye el nuevo nombre del archivo
    const newName = `${nameWithoutExtension}.${originalExtension}`;
    const renamedFile = new File([originFile], newName.replace(/\s/g, ''), { type: originFile.type });


    const renamedNzFile: NzUploadFile = {
      ...file,
      name: renamedFile.name,
      originFileObj: renamedFile
    };

    this.fileList = [renamedNzFile];  // Show the file in the list
    this.fileSelected.emit(renamedNzFile);
  }
  handlePreview = (file: NzUploadFile): void => {
    if (file) {
      if (this.isImage(file)) {
        this.showImagePreview(file);
      } else if (this.isPDF(file)) {
        this.showPdfPreview(file);
      } else {
        this.notification.error(
          '¡Error!',
          'Archivo no compatible para vista previa.',
          {
            nzStyle: { background: 'red', color: 'white' }
          }
        );
        console.warn('Archivo no compatible para vista previa.');
      }
    } else {
      this.notification.error(
        '¡Error!',
        'Archivo no disponible para vista previa en este momento.',
        {
          nzStyle: { background: 'red', color: 'white' }
        }
      );
      console.warn('Archivo no disponible para vista previa en este momento.');
    }

  }
  async downloadFile(file: NzUploadFile): Promise<void> {
    if (file.url) {
      // Si hay una URL disponible, utiliza esa URL para la descarga
      try {
        const response = await fetch(file.url);
        if (!response.ok) {
          throw new Error('Error al obtener el archivo.');
        }
        const blob = await response.blob();
        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);
        link.href = url;
        link.download = file.name || 'archivo';
        document.body.appendChild(link); // Necesario para Firefox
        link.click();
        URL.revokeObjectURL(url); // Limpia la URL del objeto
        document.body.removeChild(link); // Limpia después de usarlo
      } catch (error) {
        console.error('Error al descargar el archivo:', error);
      }
    } else if (file.originFileObj) {
      // Si no hay URL, utiliza base64 para la descarga
      const reader = new FileReader();

      // Leer el archivo como Data URL (base64)
      reader.readAsDataURL(file.originFileObj);

      reader.onload = () => {
        const base64DataUrl = reader.result as string;
        const link = document.createElement('a');
        link.href = base64DataUrl;
        link.download = file.name || 'archivo';
        document.body.appendChild(link); // Necesario para Firefox
        link.click();
        document.body.removeChild(link); // Limpia después de usarlo
      };

      reader.onerror = (error) => {
        console.error('Error al leer el archivo:', error);
      };
    } else {
      console.warn('El archivo no tiene una URL de descarga y no hay objeto originFileObj disponible.');
    }
  }




  isImage(file: NzUploadFile): boolean {
    const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp']; // Puedes agregar más extensiones si es necesario
    const extension = file.name.split('.').pop()?.toLowerCase();
    return extension ? imageExtensions.includes(extension) : false;
  }

  isPDF(file: NzUploadFile): boolean {
    const pdfExtensions = ['pdf'];
    const extension = file.name.split('.').pop()?.toLowerCase();
    return extension ? pdfExtensions.includes(extension) : false;
  }

  showImagePreview(file: NzUploadFile): void {
    // En este if: es cuando recien se sube.
    if (file.originFileObj) {
      const reader = new FileReader();
      reader.readAsDataURL(file.originFileObj);
      reader.onload = () => {
        const previewUrl = reader.result as string;
        this.modal.create({
          nzTitle: 'Vista previa de la imagen',
          nzContent: `<img src="${previewUrl}" alt="${file.name}" class="imgpreview">`,
          nzWidth: '70%',
          nzFooter: null
        });
      };
    } else {
      // Este es en caso de que ya se encuentre en la base de datos.
      this.modal.create({
        nzTitle: 'Vista previa de la imagen',
        nzContent: `<img src="${file.url}" alt="image" class="imgpreview">`,
        nzWidth: '70%',
        nzFooter: null
      });
    }
  }


  showPdfPreview(file: NzUploadFile): void {
    // En este if: es cuando recien se sube.
    if (file.originFileObj) {
      const reader = new FileReader();
      reader.readAsDataURL(file.originFileObj);
      reader.onloadstart = () => {
      };
      reader.onload = () => {
        const pdfDataUrl = reader.result as string;
        this.modal.create({
          nzTitle: 'Vista previa del PDF',
          nzContent: PdfViewerComponent,
          nzComponentParams: {
            showModal: true,
            pdfDataUrl: pdfDataUrl,
            isServer: false
          },
          nzFooter: null,
          nzWidth: '70%',
          nzStyle: {
            top: '20px',
          }
        });
      };
      reader.onerror = (error) => {
        console.warn(error);
      };
    } else {
      // Este es en caso de que ya se encuentre en la base de datos.
      this.modal.create({
        nzTitle: 'Vista previa del PDF',
        nzContent: PdfViewerComponent,
        nzComponentParams: {
          showModal: true,
          pdfDataUrl: file.url,
          isServer: true
        },
        nzFooter: null,
        nzWidth: '70%',
        nzStyle: {
          top: '20px',
        }
      });

    }
  }


  removeFile(): boolean {
    // Handle file removal
    this.fileList = [];
    return true; // Allow the removal
  }

}
