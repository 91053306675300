import { Routes } from '@angular/router';
import { AuthGuard } from 'src/app/core/guards/auth.guard';
import { Roles } from 'src/app/models/authentication/roles.interface';
import { CatalogosComponent } from 'src/app/views/catalogos/catalogos.component';

export const CommonLayout_ROUTES: Routes = [
    //Dashboard
    {
        path: '',
        redirectTo: '/anuncios',
        pathMatch: 'full'
    },
    {
        path: 'dashboard',
        loadChildren: () => import('../../features/dashboard/dashboard.module').then(m => m.DashboardModule),
    },
    // Anuncios
    {
      path: 'anuncios',
      loadChildren: () => import('../../features/anuncios/anuncios.module').then(m => m.AnunciosModule),
      data: {
        title: 'Anuncios',
      }
    },

    //Nuevo ingreso
    {
        path: 'procesos',
        data: {
            title: '',
            roles: [Roles.ADMIN, Roles.SA, Roles.CEDG]
        },
        children: [
            {
                path: '',
                redirectTo: '/anuncios',
                pathMatch: 'full'
            },
            {
                path: '',
                loadChildren: () => import('../../views/procesos/procesos.module').then(m => m.ProcesosModule)
            },
        ]
    },
    //aspirantes
    {
        path: 'aspirantes',
        loadChildren: () => import('../../views/operacion/aspirantes/aspirantes.module').then(m => m.AspirantesModule)
    },
    //nuevo menu
    //Cierre semestre
    {
      path: 'cierre-semestre',
      data: {
          title: '',
          roles: [Roles.ADMIN, Roles.SA, Roles.CEDG]
      },
      children: [
          {
              path: '',
              loadChildren: () => import('../../views/operacion/cierreSemestre/cierre-semestre.module').then(m => m.CierreSemestreModule),
            }
      ]
  },
    {
        path: 'bajas',
        data: {
            title: 'Bajas',
            roles: [Roles.ADMIN, Roles.SA, Roles.CEDG]
        },
        loadChildren: () => import('../../views/operacion/bajas/bajas.module').then(m => m.BajasModule),
    },

    {
        path: 'regularizaciones',
        data: {
            title: 'Regularizaciones',
            roles: [Roles.ADMIN, Roles.SA, Roles.CEDG]
        },
        loadChildren: () => import('../../views/operacion/regularizacion/regularizacion.module').then(m => m.RegularizacionModule),
    },
    {
        path: 'revalidaciones',
        data: {
            title: 'Revalidaciones',
            roles: [Roles.ADMIN, Roles.SA, Roles.CEDG]
        },
        loadChildren: () => import('../../views/operacion/revalidaciones/revalidaciones.module').then(m => m.RevalidacionesModule),
    },

    {
        path: 'traslados',
        data: {
            title: 'Traslados',
            roles: [Roles.ADMIN, Roles.SA, Roles.CEDG]
        },
        loadChildren: () => import('../../views/operacion/traslados/traslados.module').then(m => m.TrasladosModule),
    },
    {
        path: 'convalidaciones',
        data: {
            title: 'Convalidaciones',
            roles: [Roles.ADMIN, Roles.SA, Roles.CEDG]
        },
        loadChildren: () => import('../../views/operacion/convalidaciones/convalidaciones.module').then(m => m.ConvalidacionesModule),
    },
    {
        path: 'equivalencias-planes',
        data: {
            title: 'Equivalencias Planes',
            roles: [Roles.ADMIN, Roles.SA, Roles.CEDG]
        },
        loadChildren: () => import('../../views/procesos/equivalenciaPlanes/equivalencia-planes.module').then(m => m.EquivalenciasPlanesModule),
    },
    {
      path: 'egresados',
      data: {
          title: 'Egresados',
          roles: [Roles.ADMIN, Roles.SA, Roles.CEDG]
      },
      loadChildren: () => import('../../views/operacion/egresados/egresados.module').then(m => m.EgresadosModule),
    },
    {
        path: 'equivalencia-alumnos',
        data: {
            title: 'Equivalencias Alumnos',
            roles: [Roles.ADMIN, Roles.SA, Roles.CEDG, Roles.CEP]
        },
        loadChildren: () => import('../../views/operacion/equivalenciaAlumnos/equivalencia-alumnos.module').then(m => m.EquivalenciaAlumnosModule),
    },
    //fin contenido menu nuevo


    //alumnos
    {
      path: 'alumnos',
      loadChildren: () => import('../../views/operacion/alumnos/alumnos.module').then(m => m.AlumnosModule)
  },

    //Ciclos Periodos
    {
        path: 'ciclos',
        data: {
            title: '',
            roles: [Roles.ADMIN, Roles.SA, Roles.CEDG]
        },
        children: [
            {
                path: '',
                redirectTo: '/anuncios',
                pathMatch: 'full'
            },
            {
                path: '',
                loadChildren: () => import('../../views/operacion/ciclosPeriodos/ciclosPeriodos.module').then(m => m.CiclosPeriodosModule)
            }
        ]
    },
    {
      path: 'reportes',
      data: {
        title: 'Reportes',
        roles: [Roles.ADMIN, Roles.SA, Roles.CEP]
      },
      children: [
        {
          path: '',
          redirectTo: '/anuncios',
          pathMatch: 'full'
        },
        {
          path: '',
          loadChildren: () => import('../../views/reportes/reportes.module').then(m => m.ReportesModule)
        }
      ]
    },
    //Documentos
    {
        path: 'documentos',
        data: {
            title: '',
            roles: [Roles.ADMIN, Roles.SA, Roles.CEDG]
        },
        children: [
            {
                path: '',
                redirectTo: '/anuncios',
                pathMatch: 'full'
            },
            {
                path: '',
                loadChildren: () => import('../../views/operacion/documentosTramites/documentosTramites.module').then(m => m.DocumentosTramitesModule)
            }
        ]
    },
    //Grupos Horarios
    {
        path: 'gruposHorarios',
        data: {
            title: ''
        },
        children: [
            {
                path: '',
                redirectTo: '/anuncios',
                pathMatch: 'full'
            },
            {
                path: '',
                loadChildren: () => import('../../views/operacion/gruposHorarios/gruposHorarios.module').then(m => m.GruposHorariosModule)
            }
        ]
    },
    //Materias
    {
        path: 'materias',
        data: {
            title: ''
        },
        children: [
            {
                path: '',
                redirectTo: '/anuncios',
                pathMatch: 'full'
            },
            {
                path: '',
                loadChildren: () => import('../../views/operacion/materias/materias.module').then(m => m.MateriasModule)
            }
        ]
    },
    //Equivalencias
    {
      path: 'equivalencias',
      data: {
          title: '',
          roles: [Roles.CEDG, Roles.ADMIN, Roles.SA]
      },
      children: [
          {
              path: '',
              loadChildren: () => import('../../views/operacion/equivalencias/equivalencias.module').then(m => m.EquivalenciasModule)
          }
      ]
    },
    //Planes Estudio
    {
      path: 'planesEstudio',
      data: {
          title: ''
      },
      children: [
          {
              path: '',
              loadChildren: () => import('../../views/operacion/planesEstudio/planesEstudio.module').then(m => m.PlanesEstudioModule)
          }
      ]
  },
    // Planeacion semestre
    {
      path: 'preparacion-semestre',
      data: {
        title: 'Preparación Semestre',
      },
      children: [
        {
          path: '',
          loadChildren: () => import('../../views/operacion/preparacionSemestre/preparacion-semestre.module').then(m => m.PreparacionSemestreModule)
        }
      ]
    },
    // Clases
    {
      path: 'clases',
      data: {
        title: 'Clases',
      },
      children: [
        {
          path: '',
          loadChildren: () => import('../../views/operacion/uacs-clases/uacs-clases.module').then(m => m.UacsClasesModule)
        }
      ]
    },
    //Semestres
    {
        path: 'semestres',
        data: {
            title: ''
        },
        children: [
            {
                path: '',
                redirectTo: '/anuncios',
                pathMatch: 'full'
            },
            {
                path: '',
                loadChildren: () => import('../../views/operacion/semestres/semestres.module').then(m => m.SemestresModule)
            }
        ]
    },
    //Estadísticas
    {
      path: 'estadisticas',
      data: {
          title: ''
      },
      children: [
        {
          path: '',
          redirectTo: '/dashboard',
          pathMatch: 'full'
        },
        {
          path: '',
          loadChildren: () => import('../../views/estadisticas/estadisticas.module').then(m => m.EstadisticasModule)
        }
      ]
  },
    //Settings
    {
        path: 'settings',
        data: {
            title: ''
        },
        children: [
            {
                path: '',
                redirectTo: '/anuncios',
                pathMatch: 'full'
            },
            {
                path: '',
                loadChildren: () => import('../../views/settings/settings.module').then(m => m.SettingsModule)
            }
        ]
    },
    //Planteles
    {
        path: 'planteles',
        data: {
            title: 'Planteles'
        },
        children: [
            {
                path: '',
                loadChildren: () => import('../../views/planteles/planteles.module').then(m => m.PlantelesModule)
            }
        ]
    },
    //Roles
    {
        path: 'roles',
        data: {
            title: ''
        },
        children: [
            {
                path: '',
                redirectTo: '/anuncios',
                pathMatch: 'full'
            },
            {
                path: '',
                loadChildren: () => import('../../views/roles/roles.module').then(m => m.RolesModule)
            }
        ]
    },
    //Usuarios
    {
        path: 'usuarios',
        data: {
            title: ''
        },
        children: [
            {
                path: '',
                redirectTo: '/anuncios',
                pathMatch: 'full'
            },
            {
                path: 'list',
                loadChildren: () => import('../../views/usuarios/usuarios.module').then(m => m.UsuariosModule)
            }
        ]
    },
    {
        path: 'catalogos',
        component: CatalogosComponent,
        children: [
            {
                path: '',
                redirectTo: '/anuncios',
                pathMatch: 'full'
            },
            {
                path: '',
                loadChildren:() => import('../../views/catalogos/catalogos.module').then(m => m.CatalogosModule)
            }
        ],
        data: {
            title: 'Catálogos'
        },
    },
   {
      path: 'importaciones',
      data: {
        title: ''
      },
      children: [
        {
          path: '',
          redirectTo: '/anuncios',
          pathMatch: 'full'
        },
        {
          path: '',
          loadChildren: () => import('../../views/importaciones/importaciones.module').then(m => m.ImportacionesModule)
        }
      ]
    }

];
