import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-dynamic-icon',
  template: `
  <span nz-icon
        [nzType]="iconType"
        [nzTheme]="iconTheme"
        [ngStyle]="iconStyles"
        [ngClass]="iconStylesClass"></span>
`,
})

export class DynamicIconComponent {
  @Input() iconType: string = '';
  @Input() iconTheme: string = 'outline';
  @Input() iconStyles: { [key: string]: string | number } = {};
  @Input() iconStylesClass: string = '';


}
