import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { EnumMenu, IActionMenu, IResponseMenu, TypeAction, TypeResponseMenu } from 'src/app/models/shared/table';
import { NotificationsngzService } from 'src/app/services/shared/notificationsngz.service';
import { Store } from '@ngxs/store';
import { AuthState } from 'src/app/store/auth/states/auth.state';
import { AuthStateModel } from 'src/app/models/states/auth.state.interface';
import { Router } from '@angular/router';

@Component({
  selector: 'app-table-submenu',
  templateUrl: './table-submenu.component.html',
  styleUrls: ['./table-submenu.component.css']
})
export class TableSubmenuComponent implements OnInit {

  @Input() row: any;
  //@Input() params: string[] = [];
  @Input() typeMenu: EnumMenu = EnumMenu.NONE;
  @Input() service: any;
  @Input() acciones: IActionMenu[] = [];
  @Input() controller: string;
  @Output() actionResponseMenu = new EventEmitter<IResponseMenu>();
  @Output() editId = new EventEmitter<number>();
  @Output() emitirIdRow = new EventEmitter<number>();
  @Output() emitirIndexButton = new EventEmitter<{ id, index }>();
  @Input() redirectUrl: string;
  user = "";
  constructor(private confirmationService: ConfirmationService, private ns: NotificationsngzService, private store: Store, private router: Router) { }

  btnPersonalizados: any[];
  ngOnInit(): void {
    this.store.select(AuthState.authData).subscribe((authState: AuthStateModel) => { this.user = authState.nombre; });
    if (this.acciones.length > 0 && this.acciones.find(x => x.type === TypeAction.PERSONALIZADO)) {
      this.btnPersonalizados = this.acciones.filter(x => x.type === TypeAction.PERSONALIZADO).map(x => x.params).reduce((acc, val) => acc.concat(val), []);
    }
  }

  existeAccion(index: number) {
    return this.acciones.some(x => x.params.length > 0 && x.type == index);
  }

  obtenerParametros(accion: number) {
    let params: string[] = [];
    let accionEncontrado = this.acciones.find(x => x.type == accion);
    debugger
    if (accionEncontrado != null && accionEncontrado.params.length > 0) {
      accionEncontrado.params.forEach(param => {
        if (param.esRuta) {
          params.push(param.param)
        } else {
          params.push(this.row.id)
        }
      });
    }
    return params;
  }

  btnPersonalizado(index: number) {
    let params: string[] = [];

    let accionEncontrado = this.acciones.filter(x => x.type === TypeAction.PERSONALIZADO);

    if (index < accionEncontrado.length) {
      const acciones = accionEncontrado[index];
      const param = acciones.params[0];

      if (param.esRuta && param.id) {
        const ruta = param.param + this.row.id;
        this.router.navigate([ruta]);
        if (param.emitirId) {
          this.emitirIdData(this.row.id);
        }
      } else if (param.esRuta) {
        const ruta = param.param;
        this.router.navigate([ruta]);
        if (param.emitirId) {
          this.emitirIdData(this.row.id);
        }
      } else {
        params.push(this.row.id);
        if (param.emitirId) {
          this.emitirIdData(this.row.id);
        } else if (param.emitirIndex) {
          this.emitirIndexButtonData({ id: this.row.id, index: index });
        }
      }
    }
    return params;
  }

  confirmarEliminar(id: number) {
    if (id != null && this.service != null) {
      this.confirmationService.confirm({
        header: '¿Deseas eliminar el registro?',
        message: 'Esta acción no se podrá revertir',
        icon: 'pi pi-trash',
        acceptLabel: '¡Sí, eliminar!',
        rejectLabel: '¡No,cancelar!',
        rejectButtonStyleClass: 'p-button-outlined',
        accept: () => {
          // Lógica cuando se confirma la eliminación
          if (this.controller !== undefined) {
            this.service.delete(id, this.controller).subscribe((response: any) => {
              if (response['success']) {
                this.ns.success(response.message);
                this.actionResponseMenu.emit({
                  type: TypeResponseMenu.DELETE,
                  realizar: true,
                });
              } else {
                this.ns.error(response.message);
              }
            })
          } else {
            this.service.delete(id).subscribe((response: any) => {
              if (response['success']) {
                this.ns.success(response.message);
                this.actionResponseMenu.emit({
                  type: TypeResponseMenu.DELETE,
                  realizar: true,
                });
              } else {
                this.ns.error(response.message);
              }
            })
          }
        },
      });

    }
  }

  emitirId(id: number) {
    this.editId.emit(id);
  }

  emitirIdData(id: number) {
    this.emitirIdRow.emit(id);
  }

  emitirIndexButtonData({ id, index }) {
    this.emitirIndexButton.emit({ id, index });
  }

  deshabilitarBtn(row: any, idAccion: number): boolean {
    var btnActive: boolean = false;
    
    if (idAccion !== 0) {
      let accionEncontrado = this.acciones.filter(x => x.type === TypeAction.PERSONALIZADO);
      if (idAccion < accionEncontrado.length) {
        const acciones = accionEncontrado[idAccion];
        const param = acciones.params[0];

        var condicion = row[param.condicionalBtn];
        btnActive = condicion
      }
      return btnActive;
    }
  }
}
