import { EventEmitter, Injectable } from '@angular/core';
import autoTable from 'jspdf-autotable';
import * as XLSX from 'xlsx';
import * as saveAs from 'file-saver';
import { LazyLoadEvent } from 'primeng/api';
import { IInformation, ITableLazyLoad } from 'src/app/models/shared/table';
import { IColumnsExport } from '../../models/shared/table';
import { BehaviorSubject } from 'rxjs';



@Injectable({
  providedIn: 'root'
})
export class TableService {

  exportPdf(columnas: any[], contenido: any[], title: string) {
    // console.log(contenido);
    import("jspdf").then(jsPDF => {
      let pdf_Extension = ".pdf"
      let contenidoCamelCase = this.convertToCamelCase(contenido);
      import("jspdf-autotable").then(x => {
        const doc = new jsPDF.default('l', 'mm', 'a4');
        autoTable(doc, { columns: columnas, body: contenidoCamelCase });
        doc.save(`${title}${pdf_Extension}`);
      })
    })
  }

  exportExcel(contenido: any[], title: string) {
    import("xlsx").then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(contenido);
      const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, title);
    });
  }

  exportExcelHeaders(columnas: any[], contenido: any[], title: string) {
    // Obtiene los títulos de las columnas
    let columnasString: string[] = columnas.map(i => i.dataKey);
    let columnasTitle: string[] = columnas.map(i => i.title);
    let contenidoCamelCase = this.convertToCamelCase(contenido);
    // Filtra el contenido para que solo contenga las columnas especificadas
    let contenidoFiltrado = contenidoCamelCase.map(item => {
      let filteredItem: any = {};
      columnasString.forEach(col => {
        if (item.hasOwnProperty(col)) {
          filteredItem[col] = item[col];
        }
      });
      return filteredItem;
    });

    // Convierte los títulos de las columnas en una matriz para el encabezado
    // let heading = [columnasString];
    let heading = [columnasTitle];

    // Crea un nuevo libro de trabajo y una nueva hoja de trabajo
    const wb = XLSX.utils.book_new();
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);

    // Agrega el encabezado a la hoja de trabajo
    XLSX.utils.sheet_add_aoa(ws, heading);

    // Agrega el contenido filtrado a la hoja de trabajo, omitiendo el encabezado
    XLSX.utils.sheet_add_json(ws, contenidoFiltrado, { origin: 'A2', skipHeader: true });

    // Agrega la hoja de trabajo al libro de trabajo
    XLSX.utils.book_append_sheet(wb, ws, 'data');

    // Escribe el libro de trabajo a un buffer de Excel
    const excelBuffer: any = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

    // Guarda el archivo de Excel
    this.saveAsExcelFile(excelBuffer, title);
  }
  saveAsExcelFile(buffer: any, fileName: string): void {
    import("file-saver").then(FileSaver => {
      let EXCEL_TYPE =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      let EXCEL_EXTENSION = ".xlsx";
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      saveAs(
        data,
        fileName + EXCEL_EXTENSION
      );
    });
  }


  loadDataLazy(event: LazyLoadEvent): ITableLazyLoad {
    const currentPage = event.first / event.rows;
    const filters = this.tableFilters(event.filters);
    // console.log(filters);

    var data: ITableLazyLoad =
    {
      actualPage: currentPage,
      page: event.first != null ? event.first : 0,
      pageSize: event.rows || 10,
      sortField: event.sortField != null ? event.sortField : '',
      sortOrder: event.sortOrder != null && event.sortField != null && event.sortOrder !== -1? event.sortOrder : 0,
      filter: event.globalFilter != null ? event.globalFilter : '',
      columnasFiltro: filters,
    };
    // console.log(data);
    return data;
  }


  tableFilters(filters: { [key: string]: any }): any {
    const processedFilters: any = {};
    for (const field in filters) {
      const filterValue = filters[field].value;
      if (filterValue !== null && filterValue !== undefined && filterValue !== '') {
        processedFilters[field] = filterValue;
      }
    }
    return processedFilters;
  }

  columnasExportar(data: IInformation): any {
    const columnasExport: IColumnsExport[] = [];
    data.columns.forEach(col => {
      if (col.key != null && col.isExport == true) {
        const array: IColumnsExport = {
          key: col.key,
          title: col.title,
          isExport: col.isExport
        };
        columnasExport.push(array);
      }
    })
    return columnasExport;
  }

  convertToCamelCase(obj: any): any {
    if (!obj || typeof obj !== 'object') {
      return obj;
    }

    if (Array.isArray(obj)) {
      return obj.map(v => this.convertToCamelCase(v));
    }

    return Object.keys(obj).reduce((result, key) => {
      const newKey = key.charAt(0).toLowerCase() + key.slice(1);
      result[newKey] = this.convertToCamelCase(obj[key]);
      return result;
    }, {});
  }

  constructor() { }

  private matricularSelect = new BehaviorSubject<boolean>(false);
  exitoMatricular = this.matricularSelect.asObservable();


  exitoSelectMatricular(exito: boolean) {
    this.matricularSelect.next(exito);
  }


  exportErrorImportAspirantesPdf(columnas: any[], contenido: any[], title: string) {
    import("jspdf").then(jsPDF => {
      let pdf_Extension = ".pdf"
      let contenidoCamelCase = this.convertToCamelCase(contenido);
      import("jspdf-autotable").then(x => {
        const doc = new jsPDF.default('l', 'mm', 'a4');
        autoTable(doc, { columns: columnas, body: contenidoCamelCase });
        doc.save(`${title}${pdf_Extension}`);
      })
    })
  }
}
